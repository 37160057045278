@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
html, body, #root {
    position: relative;
    height: 100%;
}

@font-face {
    font-family: "Pretendard-Bold";
    font-weight: 700;
    font-display: swap;
    src: local("Pretendard Bold"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Bold.woff2") format("woff2"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Bold.woff") format("woff");
}
@font-face {
    font-family: "Pretendard-SemiBold";
    font-weight: 600;
    font-display: swap;
    src: local("Pretendard SemiBold"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Pretendard-Medium";
    font-weight: 500;
    font-display: swap;
    src: local("Pretendard Medium"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Medium.woff") format("woff");
}
@font-face {
    font-family: "Pretendard-Regular";
    font-weight: 400;
    font-display: swap;
    src: local("Pretendard Regular"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Regular.woff") format("woff");
}

@font-face {
    font-family: 'STIXGeneral';
    src: url('./resource/font/stixtwotext-italicwght-webfont.woff2') format('woff2'),
    url('./resource/font/stixtwotext-italicwght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'STIXGeneral';
    src: url('./resource/font/stixtwotextwght-webfont.woff2') format('woff2'),
    url('./resource/font/stixtwotextwght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Serif KR-DIAGNOSIS-PRINT';
    src: url('./resource/font/NotoSerifKR-SemiBold.woff2') format('woff2'),
    url('./resource/font/NotoSerifKR-SemiBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}



table.basic  {
    table-layout: fixed;
    width: 100%;
    border-collapse:collapse;
    border-color:#ccc;
    border-spacing:0;
    vertical-align: middle;
    text-align: center;
    word-break: break-all;
    overflow-wrap: break-word;
}

table.basic tr{
    height: 40px;
}
table.basic.dim td, table.basic.dim td.header{
    background: #eee;
    color: #999;
}
table.basic td{
    background-color:#fff;
    border-color:#ccc;
    border-style:solid;
    border-width:1px;
    color:#333;
}

table.basic td.header{
    font-weight: 800;
    background-color:#eee;
    border-color:#ccc;
}
table.basic td{
    background-color:#fff;
    border-color:#ccc;
}
table.basic td.no-border{
    background-color:#fff;
    border-color:#ccc;
    border-width:0;
}
textarea {
    font-size: 16px;
}
.font20 {font-size: 20px;}
.font30 {font-size: 30px;}
.ta-center {text-align: center;}
.ta-left {text-align: left;}
.ta-right {text-align: right;}
.va-bottom {vertical-align: bottom;}
.bold {font-weight: bold;}

table.qr td{
    border-color:#000;
    border-width:2px;
}


/* for print */
@media print {
    table {
        position: absolute;
        top:10%;
        left:10%;
    }
    table.label tr{ height: 20px !important; }
    table.label {
        width: 10cm !important;
        height: 3cm !important;
    }
    td {
        word-break: break-word;
        font-size: 8pt;
        line-height: 10px;
    }
}
